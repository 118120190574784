<template>
    <v-container>
      <v-card class="pa-4 ma-auto" style="max-width:1200px">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="isLoading"
          :size="100"
          class="ma-auto"
        ></v-progress-circular>
        <template v-else>
          <v-row>
              <v-col>
                  <h1>โปรดใส่รหัสยืนยันอีเมล</h1>
                  <h4>ระบบได้ทำการส่งรหัสยืนยันอีเมลไปทาง {{ email }} แล้ว โปรดทำการยืนยันภายในเวลา {{ before }}</h4>
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                  <v-otp-input
                      length="6"
                      type="number"
                      v-model="otp"
                      @finish="onFinish"
                      style="max-width: 400px"
                      class="mx-auto"
                  ></v-otp-input>
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                  <div class="text-subtitle-1">* หากไม่ได้รับอีเมล หรือ ไม่สามารถทำการยืนยันอีเมลภายในระยะเวลาที่กำหนด กด <a @click.prevent.stop="issueVerificationCode">ที่นี่</a> เพื่อขอรับรหัสอีกครั้ง</div>
              </v-col>
          </v-row>
        </template>
      </v-card>        
    </v-container>
</template>
<script>
import { asyncGet, asyncPost } from '../helpers/asyncAxios'
import { dateObjectToStandardAooFormat } from '../helpers/dateTimeConverter'
export default {
  data() {
    return {
      email: '',
      before: '',
      otp: null,
      isLoading: false,
    }
  },
  methods: {
    async onFinish() {
      this.isLoading = true
      try {
        let response = await asyncPost('/verify', {
          code: this.otp,
        })

        const { user } = response

        this.$store.commit('auth/SET_USER_PROFILE', user)

        if (this.$route.query.redirectTo) {
          window.location.href = this.$route.query.redirectTo
          return
        }
        this.$router.push({ name: 'companies' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.isLoading = false
    },
    async issueVerificationCode() {
      this.isLoading = true

      try {
        let response = await asyncGet('/issueVerificationCode')

        const { user } = response

        this.$store.commit('auth/SET_USER_PROFILE', user)

        const beforeDate = this.$store.getters['auth/verifyEmailBefore']
        this.before = dateObjectToStandardAooFormat(beforeDate)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.isLoading = false
    },
  },
  created() {
    if (this.$store.getters['auth/isVerifiedEmail'])
      return this.$router.push({ name: 'companies', query: { redirectTo: this.$route.query.redirectTo } })

    this.email = this.$store.state.auth.user.email

    const beforeDate = this.$store.getters['auth/verifyEmailBefore']
    this.before = dateObjectToStandardAooFormat(beforeDate)
  },
}
</script>